<template>
    <a class="banner-mode" href="https://betmm2.com/" target="_blank">
        <div class="mode-text">
            <div class="text-title"><span>BETMM2</span>
                <div class="text-info">
                <span>BETMM2.COM IS THE PLACE TO GAMBLE YOUR MM2 ITEMS! CLICK THE BANNER TO CHECK BETMM2.COM OUT!</span>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        name: 'BannerMode'
    }
</script>

<style scoped>
    .banner-mode {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 40px 20px 28px;
        font-family: 'Rubik';
        background-color: #1f2330;
    }

    .banner-mode .mode-text {
        width: calc(100% - 124px);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .banner-mode .text-title {
        font-size: 34px;
        font-weight: 900;
        color: #ffffff;
    }

    .banner-mode .text-title span {
        color: #265ecf;
    }

    .banner-mode .text-info {
        display: flex;
        flex-direction: column;
        font-size: 9px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .banner-mode .text-info span {
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: 900;
        color: #ffdc25;
    }

    @media only screen and (max-width: 600px) {

        .banner-mode {
            padding: 15px 20px 20px 20px;
        }

        .banner-mode img {
            width: 90px;
        }

        .banner-mode .mode-text {
            width: calc(100% - 90px);
        }

        .banner-mode .text-title {
            font-size: 24px;
        }

        .banner-mode .text-info {
            font-size: 12px;
        }

        .banner-mode .text-info span {
            font-size: 14px;
        }
    }

    @media only screen and (max-width: 500px) {

        .banner-mode img {
            width: 75px;
        }

        .banner-mode .mode-text {
            width: calc(100% - 75px);
        }

        .banner-mode .text-title {
            font-size: 22px;
        }

        .banner-mode .text-info {
            font-size: 11px;
        }

        .banner-mode .text-info span {
            font-size: 13px;
        }

    }
</style>
